<template>
    <div>
        <v-layout wrap justify-center>
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
                spinner="spinner" />
            <v-flex xs12>
                <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                    <v-flex xs11 text-left>
                        <span style="font-size: 25px;font-weight: bolder;">BOOKING DETAILS</span>
                    </v-flex>
                    <v-flex xs11 pt-5>
<v-card style="border-radius: 0px;" elevation="0">
    <v-layout wrap>
    <v-flex xs4 pl-3 pt-9 v-if="list.guestDetail">
        <span style="font-weight: bold;">Guest Name:</span> {{ list.guestDetail.guestName }}
    </v-flex>

    <v-flex xs4 pl-3 pt-9 v-if="list.reqRooms">
        <span style="font-weight: bold;">Required Rooms:</span> {{ list.reqRooms }}
    </v-flex>
    
    <v-flex xs4 pl-3 pt-9 v-if="list.guestDetail">
        <span style="font-weight: bold;">Mobile Number:</span> {{ list.guestDetail.guestMobileNo }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Email Address:</span> {{ list.guestDetail.guestEmailId }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Accompanying Persons:</span> {{ list.guestDetail.personAccompanyNo }}
    </v-flex>
    
    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Referred By:</span> {{ list.guestDetail.referredBy }}
    </v-flex>
    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Referred Person Phone Number:</span> {{ list.guestDetail.referencePersonPh }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Vehicle Numbers:</span> {{ list.guestDetail.vehicleNumbers }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 v-if="list.guestDetail">
        <span style="font-weight: bold;">Other Details:</span> {{ list.guestDetail.anyOtherDetails }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 pb-9 v-if="list.guestDetail">
        <span style="font-weight: bold;">Meals Type:</span> {{ list.guestDetail.mealsType }}
    </v-flex>

    <v-flex xs4 pl-3 pt-3 pb-9 v-if="list.guestDetail">
        <span style="font-weight: bold;">No. of Vehicles:</span> {{ list.guestDetail.noOfVehicles }}
    </v-flex>

</v-layout>


</v-card>

                    </v-flex>
                </v-layout>


            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";

export default {


    data() {
        return {
            barlist: [],
            appLoading: false,
            list:{},
            currentPage: 1,
msg:null,
            showSnackBar: false,
            curid: [],
            dialog2: false,


        };
    },

    mounted() {
        this.getList();


    },
    watch: {


        currentPage() {
            this.getList();
        },
    },

    methods: {
        getList() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/single/booking",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {

                    bookingid: this.$route.query.id,


                },
            })
                .then((response) => {
                    this.list = response.data.data;
                    this.appLoading = false;
                })

                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },

    },
};
</script>
    
  